import Vivus from "vivus";
export var initObserver = function initObserver() {
  var target = document.querySelector(".heart-bg");
  if (!target) return;

  var handleDrawSvgByVivus = function handleDrawSvgByVivus(entries) {
    entries.map(function (entry) {
      var id = entry.target.children[0].id;
      var myVivus = new Vivus(id.toString(), {
        duration: 500
      });
      myVivus.play();

      if (entry.isIntersecting) {} else {
        entry.target.classList.remove("visible");
      }
    });
  };

  var observer = new IntersectionObserver(handleDrawSvgByVivus);
  observer.observe(target);
};