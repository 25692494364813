import AOS from 'aos';
import { initMenu, initSearch } from "./modules/menu";
import { initObserver } from "./modules/observer";
import { initMainSlider, initInstagramSlider, initProductSlider } from "./modules/slider";
import { initVideo } from "./modules/video";
import { initPhotoSwipe } from "./modules/photoswipe";
import { initMapboxForContactPage } from './modules/mapbox';
import { loadMoreOffers } from "./modules/post-queries";
import { initForm } from './modules/contact';

var initAos = function initAos() {
  AOS.init({
    once: true,
    duration: 1000,
    mobile: false
  });
};

document.addEventListener("DOMContentLoaded", function () {
  if (document.querySelector(".posts")) {
    loadMoreOffers();
  }

  if (document.querySelector(".gallery")) {
    initPhotoSwipe();
  }

  var page = document.querySelector("body");
  var zoom_elements = document.querySelectorAll(".zoom");
  var offsetX,
      offsetY,
      x,
      y = 0;
  zoom_elements.forEach(function (zoom) {
    var backgroundImage = zoom.getAttribute("data-bg");
    zoom.addEventListener("mouseover", function (e) {
      zoom.style.backgroundImage = "url(".concat(backgroundImage, ")");
    });
    zoom.addEventListener("mouseout", function (e) {
      zoom.style.backgroundImage = "none";
    });
    zoom.addEventListener("mousemove", function (e) {
      var zoomer = e.currentTarget;
      e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX;
      e.offsetY ? offsetY = e.offsetY : offsetX = e.touches[0].pageX;
      x = offsetX / zoomer.offsetWidth * 100;
      y = offsetY / zoomer.offsetHeight * 100;
      zoomer.style.backgroundPosition = x + "% " + y + "%";
    });
  });
  initAos();
  initMenu();
  initSearch();
  initObserver();
  initVideo();

  if (page.classList.contains("home")) {
    initMainSlider();
    initInstagramSlider();
  }

  if (page.classList.contains("page-template-contact")) {
    initMapboxForContactPage();
    initForm();
  }

  if (page.classList.contains("single-produkty")) {
    initProductSlider();
  }
});