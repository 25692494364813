import axios from "axios";

var loadMore = function loadMore(name, box) {
  var postsContainer = document.querySelector(".posts");
  var postsBtn = document.querySelector("#loadmore");
  postsBtn.addEventListener("click", function (e) {
    e.preventDefault();
    var currentPage = postsContainer.dataset.page;
    var formData = new FormData();
    formData.append("action", 'loadmore');
    formData.append("current_page", currentPage);
    axios.post("/wp-admin/admin-ajax.php", formData).then(function (res) {
      var data = res.data;
      var postsList = postsContainer.querySelector(".posts-wrapper");

      if (data) {
        postsList.innerHTML += data;
        postsContainer.dataset.page++;
      } // Check if there are any elements


      var postsElements = postsContainer.querySelectorAll(".tease-post").length;
      var maxElements = postsContainer.dataset.max;

      if (postsElements == maxElements) {
        e.target.parentElement.style.display = "none";
      }
    });
  });
};

export var loadMoreOffers = function loadMoreOffers() {
  loadMore("offers", "posts");
};