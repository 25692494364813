var toggleSubmenu = function toggleSubmenu() {
  var button = document.querySelector(".menu-item-has-children");
  button.addEventListener("click", function (e) {
    if (e.target.classList.contains("open-submenu")) {
      button.classList.toggle("open_submenu");
    }
  });
};

export var initMenu = function initMenu() {
  var mediaQuery = window.matchMedia("(max-width: 992px)");
  /* Mobile menu initialization, can be done using pure js && pure css */

  if (mediaQuery.matches) {
    toggleSubmenu();
    var hamburgerEl = document.querySelector(".hamburger");
    var overlay = document.querySelector(".nav__wrapper"); // const menuItems = document.querySelectorAll(".nav__item");

    var toggleMenu = function toggleMenu() {
      hamburgerEl.classList.toggle("is-active");
      overlay.classList.toggle("is-active");
    };

    hamburgerEl.addEventListener("click", function () {
      toggleMenu();
    });
  }
};
export var initSearch = function initSearch() {
  var searchContainers = document.querySelectorAll('.nav__search');
  var currentSearch = searchContainers[0];

  if (window.matchMedia('(min-width: 992px)').matches) {
    currentSearch = searchContainers[1];
  }

  var searchButton = currentSearch.querySelector('.nav__search-button');
  currentSearch.querySelector(".nav__search-btn").addEventListener('click', function (e) {
    e.currentTarget.parentNode.classList.toggle('is-active');
    currentSearch.querySelector(".nav__search-box").focus();
  });
  searchButton.addEventListener('click', function () {
    currentSearch.classList.toggle('is-active');
  });
  currentSearch.querySelector(".nav__search-box").addEventListener('input', function (e) {
    if (e.target.value.length > 0) {
      searchButton.setAttribute('type', 'submit');
    } else {
      searchButton.setAttribute('type', 'button');
    }
  });
};