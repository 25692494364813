import _defineProperty from "@babel/runtime/helpers/defineProperty";
import SwiperCore, { Pagination, Autoplay, Navigation, EffectFade } from "swiper/core";
SwiperCore.use([Pagination, Autoplay, Navigation, EffectFade]);
export var initMainSlider = function initMainSlider() {
  var frontPageSlider = new SwiperCore("#frontpage-slider", {
    speed: 2000,
    effect: "fade",
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    loop: false,
    pagination: {
      el: ".frontpage-slider-pagination",
      clickable: true
    },
    navigation: {
      nextEl: ".slider .slider-button-next",
      prevEl: ".slider .slider-button-prev"
    }
  });
};
export var initProductSlider = function initProductSlider() {
  var frontPageSlider = new SwiperCore(".product-swiper", {
    speed: 800,
    slidesPerView: "1",
    effect: "fade",
    fadeEffect: {
      crossFade: true
    },
    loop: false,
    pagination: {
      el: ".product-pagination",
      clickable: true
    }
  });
};
export var initInstagramSlider = function initInstagramSlider() {
  var _SwiperCore;

  var container = document.querySelector(".instagram-slider");
  if (!container) return;
  var instagramSlider = new SwiperCore(".instagram-slider", (_SwiperCore = {
    speed: 200,
    slidesPerView: "3",
    spaceBetween: 15
  }, _defineProperty(_SwiperCore, "speed", 1000), _defineProperty(_SwiperCore, "autoplay", {
    delay: 3000,
    disableOnInteraction: true
  }), _defineProperty(_SwiperCore, "breakpoints", {
    480: {
      slidesPerView: "3"
    },
    768: {
      slidesPerView: "3",
      centeredSlides: true
    },
    1028: {
      slidesPerView: "6"
    }
  }), _defineProperty(_SwiperCore, "loop", true), _defineProperty(_SwiperCore, "pagination", {
    el: ".slider-pagination",
    clickable: true
  }), _SwiperCore));
};