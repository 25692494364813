import mapboxgl from 'mapbox-gl';
var apiKey = 'pk.eyJ1IjoiZG9taW5pazE5OTciLCJhIjoiY2s3MzZ4NXpyMDkzbzNscW83YWp1enF6cCJ9.SrFxRfZwljKq8nUipZHQQw';
export var initMapboxForContactPage = function initMapboxForContactPage() {
  var container = document.querySelector("#contact-map");
  if (!container) return; // Get coords

  var coords = document.querySelectorAll('.contact-map__coord'); // Main point

  var mapCenter = [coords[0].getAttribute('data-coord-y'), coords[0].getAttribute('data-coord-x')]; // Init mapbox

  mapboxgl.accessToken = apiKey;
  var map = new mapboxgl.Map({
    container: 'contact-map',
    style: 'mapbox://styles/dominik1997/ckun0vhol1y1b17o7vefjmizb',
    zoom: 8,
    center: mapCenter
  });
  map.addControl(new mapboxgl.NavigationControl());
  coords.forEach(function (el) {
    var divEl = document.createElement('div');
    divEl.className = 'marker';
    var coord = [el.getAttribute('data-coord-y'), el.getAttribute('data-coord-x')];
    new mapboxgl.Marker(divEl).setLngLat(coord).addTo(map);
  });
};