export var initForm = function initForm() {
  var $allInputs = document.querySelectorAll('.contact-form__input');
  var $wpcf7Container = document.querySelector('.wpcf7-form');

  var focusLabels = function focusLabels(item) {
    item.addEventListener('focusin', function (e) {
      e.target.closest('.contact-form__field').classList.add('is-focus');
    });
    item.addEventListener('change', function (e) {
      e.target.closest('.contact-form__field').classList.add('is-focus');
    });
    item.addEventListener('focusout', function (e) {
      if (e.target.value === '') {
        e.target.closest('.contact-form__field').classList.remove('is-focus');
      }
    });
  };

  var mailSent = function mailSent(input) {
    input.closest('.contact-form__field').classList.remove('is-focus');
  };

  $allInputs.forEach(function (input) {
    focusLabels(input);
  });
  $wpcf7Container.addEventListener('wpcf7mailsent', function (e) {
    $allInputs.forEach(function (input) {
      mailSent(input);
    });
    e.target.closest('.contact-form__form').classList.add('is-send');
  }, false);
  var closeSentMsgBtn = document.querySelector('.contact-form__close-msg');
  closeSentMsgBtn.addEventListener('click', function () {
    $wpcf7Container.closest('.contact-form__form').classList.remove('is-send');
  });
};